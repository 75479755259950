import React from "react"
import styles from "./styles.module.scss"

const IconBlock = ({ center }) => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Icon Block</div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/airCondition.svg"
              alt="bullet"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>Ducted Air Conditioning</div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img src="/img/ceiling.svg" alt="bullet" className={styles.img} />
          </div>
          <div className={styles.title}>Raised 2590 Ceilings</div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img src="/img/bathroom.svg" alt="bullet" className={styles.img} />
          </div>
          <div className={styles.title}>
            Nicjes to bathroom & Ensuite Showers
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img src="/img/woman.svg" alt="bullet" className={styles.img} />
          </div>
          <div className={styles.title}>Professional Style Experience</div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img src="/img/shoe.svg" alt="bullet" className={styles.img} />
          </div>
          <div className={styles.title}>Generous Site Works Allowance</div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img src="/img/lip-stick.svg" alt="bullet" className={styles.img} />
          </div>
          <div className={styles.title}>LED Downlilghts in Every Room</div>
        </div>
      </div>
    </section>
  )
}

export default IconBlock
