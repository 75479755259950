import React from "react"
import styles from "./styles.module.scss"

const IntroTop = () => {
  return (
    <section className={styles.container}>
      <div className={styles.caption}>
        <div className={styles.sub}>Buy a new birdy home and live</div>
        <div className={styles.main}>Pool side living</div>
      </div>
      <div className={styles.logoBirdy}>
        <img src="/img/birdy.svg" alt="cart popup" />
      </div>
      <div className={styles.logoTribeca}>
        <img src="/img/tribecaIcon.svg" alt="cart popup" />
      </div>
    </section>
  )
}

export default IntroTop
