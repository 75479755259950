import React from "react"
import styles from "./styles.module.scss"

const IdeasOptions = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>With plenty of standard extras</div>
      <ul className={styles.list}>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>
            Putting the items in the centre and the Lux and forever options on
            either side
          </div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>
            Breaking it up into two sections “General Items” and “Kitchen and
            Bathroom”
          </div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Make sure we have a download options CTA button somewhere</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>
            Happy for you to be super creative with this so it looks awesome
          </div>
        </li>
      </ul>
    </section>
  )
}

export default IdeasOptions
