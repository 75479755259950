import React from "react"
import styles from "./styles.module.scss"

const Quote = () => {
  return (
    <section className={styles.container}>
      <div className={styles.imgWrap}>
        <img src="/img/quote.svg" alt="quote" className={styles.img} />
      </div>
      <div className={styles.quote}>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui
        blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
      </div>
    </section>
  )
}

export default Quote
