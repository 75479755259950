import React from "react"
import styles from "./styles.module.scss"

const ImageOrIconsTwoColumn = ({ center }) => {
  const style = center
    ? { display: "flex", flexDirection: "column", alignItems: "center" }
    : {}
  return (
    <section className={styles.container} style={style}>
      <div className={styles.title}>2 Column With Image or Icons</div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.miniTitle}>Lorem ipsum dolor</div>
          <div className={styles.name}>HOME PORTFOLIO</div>
          <div className={styles.content}>
            Do a really cool fan-like photoshoot or simply enjoy that fresh air.
            Those 40 degree summer days and freezing winters are now under
            control.
          </div>
          <a className={styles.link}>More know About us</a>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/christian-mackie-6-b-ju-73-u-jpg-unsplash.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.miniTitle}>Lorem ipsum dolor</div>
          <div className={styles.name}>BIRDY STYLE EXPERIENCE</div>
          <div className={styles.content}>
            Do a really cool fan-like photoshoot or simply enjoy that fresh air.
            Those 40 degree summer days and freezing winters are now under
            control.
          </div>
          <a className={styles.link}>More know About us</a>
        </div>
      </div>
    </section>
  )
}

export default ImageOrIconsTwoColumn
