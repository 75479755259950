import React from "react"
import styles from "./styles.module.scss"

const HeadingOptionalLeft = () => {
  return (
    <section className={styles.container}>
      <div className={styles.oneColumn}>One Column</div>
      <div className={styles.headerOptional}>Heading optional</div>
      <div className={styles.content}>
        Still privately owned, the Tribeca team – licensed by the Building
        Services Authority (BSA) and the Housing Industry Association (HIA) –
        proudly operating from offices in Sydney, Melbourne, Gold Coast and
        Brisbane, has delivered over 5,000 family homes across QLD, NSW and VIC.
      </div>
      <div className={styles.buttonWrap}>
        <div className={styles.buttonContact}>CONTACT</div>
        <div className={styles.buttonLearnMore}>LEARN MORE</div>
      </div>
    </section>
  )
}

export default HeadingOptionalLeft
