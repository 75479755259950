import React from "react"
import styles from "./styles.module.scss"

const MeetProjectTeam = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Meet the Project Team</div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <img src="/img/christop-3.png" alt="ava" className={styles.img} />
          <div className={styles.name}>Justine Val</div>
          <div className={styles.position}>Co-founder & Managing Director</div>
        </div>
        <div className={styles.card}>
          <img src="/img/stuart-2-edit.png" alt="ava" className={styles.img} />
          <div className={styles.name}>Justine Mac</div>
          <div className={styles.position}>Co-founder & Creative Director</div>
        </div>
        <div className={styles.card}>
          <img src="/img/matt-r-3.png" alt="ava" className={styles.img} />
          <div className={styles.name}>Justine Juzzy</div>
          <div className={styles.position}>Partner & Client Director</div>
        </div>
        <div className={styles.card}>
          <img src="/img/hege-2.png" alt="ava" className={styles.img} />
          <div className={styles.name}>Alice May</div>
          <div className={styles.position}>Client Strategy Director</div>
        </div>
      </div>
    </section>
  )
}

export default MeetProjectTeam
