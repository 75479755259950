import React from "react"
import styles from "./styles.module.scss"
import ReactPlayer from "react-player/youtube"

const TerraceLocation = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Terrace Location</div>
      <div className={styles.imgWrap}>
        <img src="/img/map.png" alt="map" className={styles.img} />
      </div>
      <div className={styles.cap}>
        North Harbour is a new and exciting social and recreational destination
        in the Moreton Bay region boasting open and park space within the
        Northern Brisbane corridor.
      </div>
    </section>
  )
}

export default TerraceLocation
