import React from "react"
import styles from "./styles.module.scss"

const Benefits = ({ center }) => {
  const style = center
    ? { display: "flex", flexDirection: "column", alignItems: "center" }
    : {}
  return (
    <section className={styles.container} style={style}>
      <div className={styles.threeColumn}>Three Column</div>
      <div className={styles.title}>Benefits</div>
      <hr className={styles.hr}></hr>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes-39.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>No drip pricing promise</div>
          <p>
            It really gets our goat when our mates next door advertise low base
            prices but who are they fooling… just themselves lol. We care that
            you have everything you need to move into your new digs from day
            one.
          </p>
          <p>
            If you want to get a bit more fancy, we also have optional upgrades
            available that won't cost the earth. I mean surely buying a house
            (your largest investment EVER!) is costly enough and we respect
            that!
          </p>

          {!center && <div className={styles.buttonLearnMore}>LEARN MORE</div>}
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes-39.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>Build time guarantee</div>
          <p>
            We know you’ve heard the horror stories of build time blow outs, but
            you have nothing to worry about over here. With a refined process,
            Birdy offers clients a 22 week build time guarantee for single story
            homes and a 27 week guarantee for two story homes.
          </p>
          <p>
            Count on us for realistic time frames, so you can plan your life. We
            all know that additional costs ain't cool, so we have removed those
            shi**y surprises completely - it's our true pricing policy.
          </p>
          {!center && <div className={styles.buttonLearnMore}>LEARN MORE</div>}
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes-39.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>
            Your complimentary Style Experience
          </div>
          <p>
            We’re introducing a style experience unlike any other and it’s a tad
            bit exclusive. Only our Birdy guys and gals get in. Whilst we work
            with some of the best in the Instagram world, our style experience
            is a true collab between client and designer.
          </p>
          <p>
            Enjoy a complimentary design service with our style squad in our
            gorge Birdy studio, the coolest of spaces oozing with inspo and all
            of your ideas.
          </p>
          {!center && <div className={styles.buttonLearnMore}>LEARN MORE</div>}
        </div>
      </div>
    </section>
  )
}

export default Benefits
