import React from "react"
import styles from "./styles.module.scss"
import ReactPlayer from "react-player/youtube"

const RelativeArticles = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Related Articles</div>
      <div className={styles.heading}>Related Articles</div>

      <div className={styles.cards}>
        <div className={styles.card}>
          <img
            src="/img/spoons-180-x-copy-4.png"
            alt="ava"
            className={styles.img}
          />
          <div className={styles.topLeft}>Vestibulum gravida elementu m</div>
        </div>
        <div className={styles.card}>
          <img
            src="/img/christian-mackie-6-b-ju-73-u-jpg-unsplash-copy-6.png"
            alt="ava"
            className={styles.img}
          />
          <div className={styles.topLeft}>Vestibulum gravida elementu m</div>
        </div>
        <div className={styles.card}>
          <img
            src="/img/christian-mackie-6-b-ju-73-u-jpg-unsplash-copy-8.png"
            alt="ava"
            className={styles.img}
          />
          <div className={styles.topLeft}>Vestibulum gravida elementu m</div>
        </div>
        <div className={styles.card}>
          <img
            src="/img/christian-mackie-6-b-ju-73-u-jpg-unsplash-copy-7.png"
            alt="ava"
            className={styles.img}
          />
          <div style={{ color: "white" }} className={styles.topLeft}>
            LOREM IPSUM DOLOR SIT AMET
          </div>
        </div>
      </div>
    </section>
  )
}

export default RelativeArticles
