import React from "react"
import styles from "./styles.module.scss"

const FinishesInclusions = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Finishes & Inclusions</div>
      <div className={styles.content}>
        <div className={styles.imgWrap}>
          <img
            src="/img/screen-shot-2020-10-30-at-15-21-35.png"
            alt="imageCard"
            className={styles.img}
          />
        </div>
      </div>
    </section>
  )
}

export default FinishesInclusions
