import React from "react"
import styles from "./styles.module.scss"

const TerraceLiving = ({ center }) => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Terrace living</div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes-39.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>TRIBECA NORTH HARBOUR</div>
          <div className={styles.lot}>LOT 670 + 677</div>

          <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores Etiam tempus lacinia magna eu tincidunt. Etiam lacus enim.
          </p>

          <div className={styles.buttonLearnMore}>LEARN MORE</div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes-39.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>TRIBECA NORTH HARBOUR</div>
          <div className={styles.lot}>LOT 670 + 677</div>

          <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores Etiam tempus lacinia magna eu tincidunt. Etiam lacus enim.
          </p>

          <div className={styles.buttonLearnMore}>LEARN MORE</div>
        </div>
        <div className={styles.card}>
          <div className={styles.imgWrap}>
            <img
              src="/img/elara-display-homes-39.png"
              alt="imageCard"
              className={styles.img}
            />
          </div>
          <div className={styles.title}>TRIBECA NORTH HARBOUR</div>
          <div className={styles.lot}>LOT 670 + 677</div>

          <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores Etiam tempus lacinia magna eu tincidunt. Etiam lacus enim.
          </p>

          <div className={styles.buttonLearnMore}>LEARN MORE</div>
        </div>
      </div>
    </section>
  )
}

export default TerraceLiving
