import React from "react"
import styles from "./styles.module.scss"

const StandardExtra = ({ isLearnMore }) => {
  return (
    <section className={styles.container}>
      <div className={styles.twoColumn}>Two Column</div>
      <div className={styles.title}>With plenty of standard extras</div>
      <ul className={styles.list}>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Modern Architechtural designed</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>No body corporate fees</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Oversized Kitchen Island Bench</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>900mm Cooking Appliances</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Shower Niches to Bathroom and Ensuite</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Ducted Air Conditioning</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Designer Colour Schemes</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Steel frames</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Courtyard to Master Suite (Lot 670 & 677)</div>
        </li>
        <li className={styles.li}>
          <img src="/img/bullet.svg" alt="bullet" className={styles.bullet} />
          <div>Double Garage (Lot 670 & 677)</div>
        </li>
      </ul>
      {isLearnMore && (
        <div className={styles.buttonWrap}>
          <div className={styles.buttonLearnMore}>LEARN MORE</div>
        </div>
      )}
    </section>
  )
}

export default StandardExtra
