import React from "react"
import styles from "./styles.module.scss"

const FeaturedHome = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Featured Home & Land Design</div>
      <div className={styles.content}>
        <div className={styles.imgWrap}>
          <img src="/img/img-01.png" alt="imageCard" className={styles.img} />
        </div>
        <div className={styles.detail}>
          <div className={styles.name}>Charlie 2 </div>
          <div className={styles.info}>Location: 3 Burpengary East, QLD</div>
          <div className={styles.info}>Lot No: 3</div>
          <div className={styles.info}>Price: $499,800.00</div>
          <div className={styles.paraWrap}>
            <p>
              North Harbour is a new and exciting social and recreational
              destination in the Moreton Bay reagion boasting open and park
              space within the Northern Brisbane corridor. With extensive bike
              and walking tracks, you and the family will have plenty of space
              exercise and fun active lifestyle.
            </p>
            <p>
              There is no shortage of cafes, retial shopping and restaurants -
              or maybe you prefer to catch your dinner NorthHarbour offers
              recreational fishing areas, a place to hit the see for the sailing
              enthusiasts or simply explore the many wonders within the
              caboolture river and Pumicestone passage will truly feel right at
              home
            </p>
          </div>

          <div className={styles.button}>ENQUIRE TODAY</div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedHome
