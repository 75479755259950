import React from "react"
import styles from "./styles.module.scss"
import ReactPlayer from "react-player/youtube"

const VideoEmbed = () => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>Video Embed Block</div>
      <div className={styles.videoWrap}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          width="100%"
          height="100%"
        />
      </div>
    </section>
  )
}

export default VideoEmbed
