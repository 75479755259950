import React from "react"
import styles from "./styles.module.scss"

const FollowUs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Follow Us</div>
    </div>
  )
}

export default FollowUs
