import React from "react"
import HeadingOptionalCenter from "../components/ContentBuilder/HeadingOptionalCenter"
import IntroTop from "../components/ContentBuilder/IntroTop"
import HeadingOptionalLeft from "../components/ContentBuilder/HeadingOptionalLeft"
import StandardExtra from "../components/ContentBuilder/StandardExtras"
import Benefits from "../components/ContentBuilder/Benefits"
import Quote from "../components/ContentBuilder/Quote"
import MeetProjectTeam from "../components/ContentBuilder/MeetProjectTeam"
import IdeasOptions from "../components/ContentBuilder/IdeasOptions"
import VideoEmbed from "../components/ContentBuilder/VideoEmbed"
import TerraceLiving from "../components/ContentBuilder/TerraceLiving"
import IconBlock from "../components/ContentBuilder/IconBlock"
import TerraceLocation from "../components/ContentBuilder/TerraceLocation"
import RelativeArticles from "../components/ContentBuilder/RelativeArticles"
import ImageOrIcons from "../components/ContentBuilder/ImageOrIcons"
import ImageOrIconsTwoColumn from "../components/ContentBuilder/ImageOrIconsTwoColumn"
import FinishesInclusions from "../components/ContentBuilder/FinishesInclusions"
import FeaturedHome from "../components/ContentBuilder/FeaturedHome"
import FollowUs from "../components/ContentBuilder/FollowUs"

const TestPage = () => {
  return (
    <>
      <IntroTop />
      <HeadingOptionalCenter />
      <HeadingOptionalLeft />
      <StandardExtra />
      <StandardExtra isLearnMore />
      <Benefits />
      <Quote />
      <Benefits center />
      <MeetProjectTeam />
      <IdeasOptions />
      <VideoEmbed />
      <TerraceLiving />
      <IconBlock />
      <TerraceLocation />
      <RelativeArticles />
      <ImageOrIcons />
      <ImageOrIconsTwoColumn />
      <FinishesInclusions />
      <FeaturedHome />
      <FollowUs />
    </>
  )
}

export default TestPage
